<template>
  <div>
    <b-container>
      <button
        v-if="showNext"
        class="input-group-text"
        v-bind:class="{
          'btn-next-grey': getGreyClass('first'),
        }"
        :disabled="getGreyClass()"
        @click="callPage('next')"
      >
        Next
      </button>
      <!-- <button
        v-else
        class="input-group-text"
        v-bind:class="{
          'btn-next-grey': getGreyClass('second'),
        }"
        @click="callPage('submit')"
      >
        Submit
      </button> -->

      <button v-else
        class="input-group-text"
        @click="callSubmitPages()"
      >
        Submit
      </button>
    </b-container>

    <b-container v-if="callDiv === 0" class="bv-example-row">
      <h2>What do you use Datagma the most for?</h2>
      <p>
        We'll use this to recommend design and template especially for you.
      </p>

      <div class="bv-example-inner step_1_div">
        <b-row class="d-lg-flex d-md-block d-sm-block d-block">
          <b-col>
            <div
              v-bind:class="firstPage.PhoneNumbers ? 'active text' : 'text'"
              @click="callSegment('PhoneNumbers')"
            >
              <h4>Get Mobile Phone Numbers</h4>
              <span>
                <p>
                  I need to find mobiles phone numbers
                </p>
              </span>
            </div>
          </b-col>
          <b-col>
            <div
              v-bind:class="firstPage.Email ? 'active text' : 'text'"
              @click="callSegment('Email')"
            >
              <h4>Get Verified Emails</h4>
              <span>
                <p>
                  I need to find work verified emails
                </p>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="d-lg-flex d-md-block d-sm-block d-block">
          <b-col>
            <div
              v-bind:class="firstPage.CompanyData ? 'active text' : 'text'"
              @click="callSegment('CompanyData')"
            >
              <h4>Get Company Data</h4>
              <span>
                <p>
                  I need to have companies data
                </p>
              </span>
            </div>
          </b-col>
          <b-col>
            <div
              v-bind:class="firstPage.JobChanges ? 'active text' : 'text'"
              @click="callSegment('JobChanges')"
            >
              <h4>Track Job Changes Detection</h4>
              <span>
                <p>
                  I need to know who left a company, where he moved, and who
                  replaced him.
                </p>
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-container v-if="callDiv === 1" class="bv-example-row">
      <h2>How do you plan to interact with Datagma?</h2>
      <p>
        We'll use this to recommend design and template especially for you.
      </p>

      <div class="bv-example-inner step_2_div">
        <b-row class="d-lg-flex d-md-block d-sm-block d-block">
          <b-col>
            <div
              v-bind:class="
                secondPage.ChromeExtensionAndSearchEngine
                  ? 'active text'
                  : 'text'
              "
              @click="callSegment('ChromeExtensionAndSearchEngine')"
            >
              <div class="img-div">
                <img
                  style="height:54px;"
                  src="@/assets/images/chrome-img.svg"
                  alt=""
                />
              </div>
              <h4>Chrome Extension & Search Engine</h4>
              <span>
                <p>
                  I will use the Datagma search engine & extension to find
                  people
                </p>
              </span>
            </div>
          </b-col>
          <b-col>
            <div
              v-bind:class="secondPage.FileUpload ? 'active text' : 'text'"
              @click="callSegment('FileUpload')"
            >
              <div class="img-div">
                <svg
                  width="77"
                  height="54"
                  viewBox="0 0 77 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M65.2344 23.5312C63.5519 10.2795 52.206 0 38.5 0C27.8894 0 18.6725 6.20235 14.4644 15.9775C6.19465 18.4492 0 26.257 0 34.65C0 45.2645 8.63555 53.9 19.25 53.9H61.6C70.0931 53.9 77 46.9931 77 38.5C76.9941 35.0489 75.832 31.6993 73.6994 28.986C71.5667 26.2727 68.5864 24.3522 65.2344 23.5312ZM42.35 34.65V46.2H34.65V34.65H23.1L38.5 15.4L53.9 34.65H42.35Z"
                    fill="#2589FF"
                  />
                </svg>
              </div>
              <h4>File Upload</h4>
              <span>
                <p>
                  I will upload incomplete files, and I will let Datagma enhance
                  it
                </p>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="d-lg-flex d-md-block d-sm-block d-block">
          <b-col>
            <div
              v-bind:class="secondPage.Integrations ? 'active text' : 'text'"
              @click="callSegment('Integrations')"
            >
              <div class="img-div">
                <svg
                  width="49"
                  height="54"
                  viewBox="0 0 49 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M43.2 5.4H31.914C30.78 2.268 27.81 0 24.3 0C20.79 0 17.82 2.268 16.686 5.4H5.4C5.022 5.4 4.671 5.427 4.32 5.508C3.46009 5.68842 2.65691 6.07513 1.97961 6.63484C1.30232 7.19455 0.771207 7.91048 0.432 8.721C0.162 9.342 0 10.044 0 10.8V48.6C0 49.329 0.162 50.058 0.432 50.706C0.702 51.354 1.107 51.921 1.593 52.434C2.322 53.163 3.267 53.703 4.32 53.919C4.671 53.973 5.022 54 5.4 54H43.2C46.17 54 48.6 51.57 48.6 48.6V10.8C48.6 7.83 46.17 5.4 43.2 5.4ZM21.6 35.559L17.793 39.393L8.1 29.7L17.793 20.007L21.6 23.841L15.741 29.7L21.6 35.559ZM24.3 8.775C23.193 8.775 22.275 7.857 22.275 6.75C22.275 5.643 23.193 4.725 24.3 4.725C25.407 4.725 26.325 5.643 26.325 6.75C26.325 7.857 25.407 8.775 24.3 8.775ZM30.807 39.393L27 35.559L32.859 29.7L27 23.841L30.807 20.007L40.5 29.7L30.807 39.393Z"
                    fill="#2589FF"
                  />
                </svg>
              </div>
              <h4>Integration & CRM</h4>
              <span>
                <p>
                  I will connect Datagma to a third-party tool (Zapier, Make,
                  N8N, HubSpot)
                </p>
              </span>
            </div>
          </b-col>
          <b-col>
            <div
              v-bind:class="secondPage.API ? 'active text' : 'text'"
              @click="callSegment('API')"
            >
              <div class="img-div">
                <svg
                  width="55"
                  height="54"
                  viewBox="0 0 55 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M53.8626 2.95918L51.0557 0.152262C50.9497 0.0463408 50.8173 0 50.6783 0C50.5393 0 50.4069 0.0529608 50.301 0.152262L45.2631 5.19014C43.073 3.70627 40.4874 2.91513 37.842 2.91946C34.4525 2.91946 31.063 4.21037 28.4746 6.79882L21.7287 13.5447C21.6301 13.6442 21.5748 13.7786 21.5748 13.9187C21.5748 14.0588 21.6301 14.1932 21.7287 14.2928L39.7155 32.2795C39.8214 32.3854 39.9538 32.4318 40.0928 32.4318C40.2252 32.4318 40.3642 32.3788 40.4701 32.2795L47.216 25.5336C51.7772 20.9658 52.3135 13.9022 48.8247 8.75175L53.8626 3.71387C54.0678 3.50202 54.0678 3.1644 53.8626 2.95918ZM31.4337 29.3005C31.3342 29.2019 31.1998 29.1466 31.0597 29.1466C30.9196 29.1466 30.7852 29.2019 30.6857 29.3005L26.2767 33.7094L20.2988 27.7315L24.7143 23.3159C24.9196 23.1107 24.9196 22.7731 24.7143 22.5679L22.3046 20.1581C22.2051 20.0596 22.0707 20.0043 21.9306 20.0043C21.7905 20.0043 21.6561 20.0596 21.5566 20.1581L17.141 24.5737L14.2943 21.7271C14.2449 21.6776 14.186 21.6387 14.1212 21.6125C14.0564 21.5863 13.9869 21.5735 13.917 21.5748C13.7846 21.5748 13.6456 21.6278 13.5397 21.7271L6.80041 28.473C2.23918 33.0342 1.70295 40.1045 5.19173 45.2549L0.153851 50.2928C0.0552898 50.3923 0 50.5267 0 50.6668C0 50.8069 0.0552898 50.9413 0.153851 51.0408L2.96077 53.8477C3.06669 53.9537 3.19909 54 3.33811 54C3.47713 54 3.60953 53.947 3.71545 53.8477L8.75334 48.8099C10.9843 50.3259 13.5794 51.0805 16.1744 51.0805C19.5639 51.0805 22.9534 49.7896 25.5419 47.2012L32.2877 40.4553C32.4929 40.2501 32.4929 39.9125 32.2877 39.7072L29.4411 36.8606L33.8567 32.445C34.0619 32.2398 34.0619 31.9022 33.8567 31.6969L31.4337 29.3005Z"
                    fill="#2589FF"
                  />
                </svg>
              </div>
              <h4>API</h4>
              <span>
                <p>
                  I will integtrate Datagma technologie in my own product via
                  API
                </p>
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-container v-if="callDiv === 2" class="bv-example-row">
      <h2>I want to integrate with:</h2>
      <p>
        We’ll use this to recommend designs and templates especially for you.
      </p>

      <div class="bv-example-inner">
        <b-row class="d-lg-flex d-md-block d-sm-block d-block">
          <b-col>
            <div
              v-bind:class="thirdPage.hubspot ? 'active text' : 'text'"
              @click="callSegment('hubspot')"
            >
              <div class="img-div">
                <div class="img-div">
                  <img
                    style="height:40px;"
                    :src="require(`@/assets/images/icons/HubSpot_Logo_new.svg`)"
                    alt=""
                  />
                </div>
              </div>
              <h4>CRM Automatic Enrichment</h4>
              <span>
                <p>
                  I will connect my CRM to Datagma to let it automatically
                  enrich my contacts and alert me when some of them move to
                  another company
                </p>
              </span>
            </div>
          </b-col>
          <b-col>
            <div
              v-bind:class="thirdPage.zapier__make ? 'active text' : 'text'"
              @click="callSegment('zapier__make')"
            >
              <div class="img-div">
                <!-- <img
                  style="height:40px;"
                  :src="require(`@/assets/images/icons/zapier_logo_new.svg`)"
                  alt=""
                /> -->
                <img
                  style="height:40px;"
                  :src="require(`@/assets/images/icons/nan_icons.svg`)"
                  alt=""
                />
              </div>
              <h4>Zapier, Make or N8N</h4>
              <span>
                <p>
                  Connect Datagma in No Code to more than 3000 tools
                </p>
              </span>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row class="d-lg-flex d-md-block d-sm-block d-block">
          <b-col>
            <div
              v-bind:class="thirdPage.SurfeClay ? 'active text' : 'text'"
              @click="callSegment('SurfeClay')"
            >
              <div class="img-div">
                <img
                  style="height:40px;"
                  :src="require(`@/assets/images/logo-icon.svg`)"
                  alt=""
                />
              </div>
              <h4>Captain Data, Clay or Surfe</h4>
              <span>
                <p>
                  Build Automation workflow in No Code
                </p>
              </span>
            </div>
          </b-col>
          <b-col>
            <div
              v-bind:class="thirdPage.Pipedrive ? 'active text' : 'text'"
              @click="callSegment('Pipedrive')"
            >
              <div class="img-div">
                <img
                  style="height:40px;"
                  src="/img/pipedrive_logo.c00ba3e4.svg"
                />
              </div>
              <h4>CRM Automatic Enrichment</h4>
              <span>
                <p>
                  I will connect my CRM to Datagma to let it automatically
                  enrich my contacts and alert me when some of them move to
                  another company
                </p>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="d-lg-flex d-md-block d-sm-block d-block">
          <b-col>
            <div
              v-bind:class="thirdPage.NotFinded ? 'active text' : 'text'"
              @click="callSegment('NotFinded')"
            >
              <div class="img-div">
                <img
                  style="height:40px;"
                  :src="require(`@/assets/images/icons/question.svg`)"
                  alt=""
                />
              </div>
              <h4>I do not find my tool here</h4>
              <span>
                <p>
                  Don’t hesitate to ask, and we will integrate
                </p>
              </span>
            </div>
          </b-col>
          <b-col></b-col>
        </b-row> -->
        <b-row class="d-lg-flex d-md-block d-sm-block d-block">
          <b-col>
            <div
              v-bind:class="thirdPage.sales_navigator ? 'active text' : 'text'"
              @click="callSegment('sales_navigator')"
            >
              <div class="img-div">
                <img
                  style="height:40px;"
                  :src="require(`@/assets/images/icons/linkedIn-in.png`)"
                  alt=""
                />
              </div>
              <h4>SALES NAVIGATOR</h4>
              <!-- <span>
                <p>
                  Sales Navigator
                </p>
              </span> -->
            </div>
          </b-col>
          <b-col></b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import { FIRST_TIME } from "../../../store/constant/actions.type";
import { analyticsService } from "../../common/services/analytics.service";
export default {
  name: "dashboard",
  data() {
    return {
      user_detail: {},
      callDiv: 0,
      totalPage: 3,
      showNext: true,
      firstPageDone: false,
      secondPageDone: false,
      thirdPageDone: false,
      firstPage: {
        PhoneNumbers: false,
        Email: false,
        CompanyData: false,
        JobChanges: false,
      },
      secondPage: {
        FileUpload: false,
        API: false,
        Integrations: false,
        ChromeExtensionAndSearchEngine: false,
      },
      thirdPage: {
        hubspot: false,
        zapier__make: false,
        sales_navigator: false,

        // SurfeClay: false,
        // Pipedrive: false,
        // NotFinded: false,
      },
    };
  },

  mounted() {
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "https://cdn.firstpromoter.com/fpr.js");
    // // externalScript.setAttribute("cid", "9iau5tg8");
    // document.head.appendChild(externalScript);
  },
  methods: {
    getGreyClass(type) {
      if (type == "first") {
        if (this.callDiv == 0) {
          if (this.firstPageDone) {
            return false;
          } else {
            return true;
          }
        }else{
          if (this.callDiv == 1) {
          if (this.secondPageDone) {
            return false;
          } else {
            return true;
          }
        }
        }
      } else if (type == "second") {
        if (this.callDiv == 1) {
          if (this.secondPageDone) {
            return false;
          } else {
            return true;
          }
        } else if (this.callDiv == 2) {
          if (this.thirdPageDone) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    callSegment(callFor) {
      if (this.callDiv == 0) {
        if (callFor === "PhoneNumbers") {
          this.firstPage.PhoneNumbers = !this.firstPage.PhoneNumbers;
        } else if (callFor === "Email") {
          this.firstPage.Email = !this.firstPage.Email;
        } else if (callFor === "CompanyData") {
          this.firstPage.CompanyData = !this.firstPage.CompanyData;
        } else if (callFor === "JobChanges") {
          this.firstPage.JobChanges = !this.firstPage.JobChanges;
        }

        if (
          this.firstPage.PhoneNumbers ||
          this.firstPage.Email ||
          this.firstPage.CompanyData ||
          this.firstPage.JobChanges
        ) {
          this.firstPageDone = true;
        } else {
          this.firstPageDone = false;
        }
      } else if (this.callDiv == 1) {
        if (callFor === "FileUpload") {
          this.showNext = true;
          this.secondPage.FileUpload = !this.secondPage.FileUpload;
        } else if (callFor === "API") {
          this.showNext = true;
          this.secondPage.API = !this.secondPage.API;
        } else if (callFor === "Integrations") {
          this.showNext = true;
          this.secondPage.Integrations = !this.secondPage.Integrations;
        } else if (callFor === "ChromeExtensionAndSearchEngine") {
          this.showNext = true;
          this.secondPage.ChromeExtensionAndSearchEngine = !this.secondPage
            .ChromeExtensionAndSearchEngine;
        }
        if (
          this.secondPage.FileUpload ||
          this.secondPage.API ||
          this.secondPage.Integrations ||
          this.secondPage.ChromeExtensionAndSearchEngine
        ) {
          this.secondPageDone = true;
        } else {
          this.secondPageDone = false;
        }
      } else {
        if (callFor === "hubspot") {
          this.thirdPage.hubspot = !this.thirdPage.hubspot;
        } else if (callFor === "zapier__make") {
          this.thirdPage.zapier__make = !this.thirdPage.zapier__make;
          // } else if (callFor === "SurfeClay") {
          //   this.thirdPage.SurfeClay = !this.thirdPage.SurfeClay;
          // } else if (callFor === "Pipedrive") {
          //   this.thirdPage.Pipedrive = !this.thirdPage.Pipedrive;
        } else if (callFor === "sales_navigator") {
          this.thirdPage.sales_navigator = !this.thirdPage.sales_navigator;
        }
        if (
          this.thirdPage.hubspot ||
          this.thirdPage.zapier__make ||
          this.thirdPage.sales_navigator
        ) {
          this.thirdPageDone = true;
        } else {
          this.thirdPageDone = false;
        }
      }
    },
    callPage(callfor) {
      if (this.firstPageDone) {
        if (callfor === "next" && this.callDiv < 1) {
          this.callDiv++;
          this.showNext = true;
          // this.showNext = !this.showNext;
          analyticsService.userGetstarted(
            "step1",
            this.firstPage,
            this.user_detail
          );
        }
        if (this.secondPageDone) {
          if (callfor === "next" && this.callDiv == 1) {
            this.callDiv++;
            this.showNext = false;
            analyticsService.userGetstarted(
              "step2",
              this.secondPage,
              this.user_detail
            );
          } else if (callfor === "submit" && this.callDiv == 1) {
            analyticsService.userGetstarted(
              "step2",
              this.secondPage,
              this.user_detail
            );
            // this.$router.push({ name: "easy-search" });
            return;
          }
          if (this.thirdPageDone) {
            analyticsService.userGetstarted(
              "step3",
              this.thirdPage,
              this.user_detail
            );
            setTimeout(() => {
              analyticsService.userGetstartedOnBoardig(
                "onBoarding",
                this.firstPage,
                this.secondPage,
                this.thirdPage
              );
              // analyticsService.userGetstarted("step3", this.thirdPage);
              if (this.callDiv == 2) {
                this.$router.push({ name: "easy-search" });
              }
            }, 1000);
          }
        }
      }
    },

    
    callSubmitPages(){
      this.thirdPageDone = true;
        if (this.thirdPageDone) {
              console.log("enter in api")
              analyticsService.userGetstarted(
                "step3",
                this.thirdPage,
                this.user_detail
              );
              setTimeout(() => {
                analyticsService.userGetstartedOnBoardig(
                  "onBoarding",
                  this.firstPage,
                  this.secondPage,
                  this.thirdPage
                );
                // analyticsService.userGetstarted("step3", this.thirdPage);
                if (this.callDiv == 2) {
                  this.$router.push({ name: "easy-search" });
                }
              }, 1000);
            }
      },
  },
  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;

    let firstTime = localStorage.getItem(FIRST_TIME);
    if (!firstTime) {
      localStorage.removeItem(FIRST_TIME);
    }
  },
};
</script>
<style scoped>
.bv-example-row p {
  margin-top: 23px;
  margin-bottom: -25px;
  margin-left: 7px;
  min-height: 98px;
}
.bv-example-row .row .col .text {
  background: white;
  padding: 32px;
  text-align: center;
}
.bv-example-row .row .col .text.active {
  background: #2589ff;
  padding: 32px;
  text-align: center;
}
.container > .row > .col-sm {
  background: red;
}
.bv-example-row h4 {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 400;
}
.bv-example-row .row .col .text.active h4,
.bv-example-row .row .col .text.active p {
  color: #fff;
}
.input-group-text {
  background: #2589ff;
  color: white;
  padding: 20px 40px;
  margin: 13px 20px;
  height: 41px;
  border-radius: 9px;
  width: 81px;
  justify-content: center;
  border: none;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
  float: right;
}
.pagination-box {
  text-align: center;
  margin-top: 30px;
}

.dots-lists span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: #c0b6f2;
  margin: -2px 5px;
}
.dots-lists span.active {
  background: #2589ff;
}
.bv-example-inner {
  background: #f7f8fa;
  padding: 0 60px;
}

.bv-example-inner .col {
  padding: 0 8px;
}
.bv-example-row .row .col .text {
  background: white;
  padding: 28px;
  text-align: center;
  margin: 38px 0 0;
  border: 2px solid #f5f5f5;
  cursor: pointer;
}
.bv-example-inner {
  padding-bottom: 40px;
  border: 1px solid #dfe1e6;
  border-radius: 8px;
}
.bv-example-row .row .col .text:hover,
.bv-example-row .row .col .text.active {
  border: 2px solid #2589ff;
}
.active path {
  fill: #fff;
}
.text.linkdin-sales {
  padding: 66px 4px !important;
}
.text.linkdin-sales h4 {
  letter-spacing: 1px;
}

@media screen and (max-width: 1300px) {
  .bv-example-row h4 {
    font-size: 20px;
  }
  .bv-example-row p {
    margin-top: 9px;
    min-height: 117px;
  }
}
@media screen and (max-width: 1200px) {
  .bv-example-row .step_1_div .row .col .text {
    height: 175px;
  }
  .bv-example-row .step_2_div .row .col .text {
    height: auto;
  }
  .bv-example-row .row .col .text {
    padding: 23px 10px;
    margin: 16px 0 0;
    height: 279px;
  }
  .bv-example-row h4 {
    font-size: 18px;
  }
  .bv-example-row p {
    font-size: 14px;
    margin-top: 7px;
  }
  .bv-example-inner {
    padding: 31px;
  }
}
@media screen and (max-width: 992px) {
  .bv-example-row .step_1_div .row .col .text,
  .bv-example-row .row .col .text {
    height: auto;
  }
  .bv-example-inner {
    padding: 18px;
    padding-top: 0px;
  }
  .bv-example-row .row .col .text {
    padding: 9px 15px;
    height: auto;
  }
  .bv-example-row h4 {
    font-size: 20px;
  }
  .bv-example-row p {
    font-size: 15px;
    margin-top: 4px;
    padding-bottom: 28px;
  }
}
.btn-next-grey {
  background: rgb(120 120 120);
}

/* @media screen and (max-width: 872px) {
  .main-content {
  }
} */
</style>
